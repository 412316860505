/* Normal styling for larger screens */
.banner h1 {
  font-size: 4em; /* Adjust as needed */
}

/* Styling for smaller/mobile screens */
@media (max-width: 768px) {
  .banner h1 {
    font-size: 1.8em; /* Adjust as needed */
  }
}

.tagline {
  margin-bottom: 20px; /* Adjust as per your requirement for desktop */
}

.txt-rotate {
  margin-bottom: 30px; /* Adjust as per your requirement for desktop */
}

/* Styling for smaller/mobile screens */
@media (max-width: 768px) {
  .tagline {
    margin-top: -50px; /* Adjust this value to position the tagline higher on mobile */
    margin-bottom: 30px; /* Adjust this value to increase the gap below the tagline on mobile */
  }

  .txt-rotate {
    margin-bottom: 10px; /* Adjust this value to increase the gap below the typing text on mobile */
  }
}

/* Normal styling for larger screens */
.banner .name-title {
  font-size: 4em; /* Adjust as needed */
}

/* Styling for smaller/mobile screens */
@media (max-width: 768px) {
  .banner .name-title {
    font-size: 2.2em; /* Adjust as needed */
  }
}

.about-me-text {
  color: #666; /* Original color for larger screens */
}

@media (max-width: 768px) {
  .about-me-text {
    color: #333; /* Darker color for mobile */
  }
}

.skill {
    position: relative;
  }
  
  .background-image-left {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    z-index: 1;
  }
  
  .background-image-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    z-index: 1;
  }
  
  .skill-bx {
    position: relative;
    z-index: 2;
    /* Your other styles */
  }

  .skill-bx p {
    margin-bottom: 30px; /* adjust as needed */
  }
  
  @media (max-width: 768px) {
    .skill-bx p {
      font-size: 0.9rem;
    }
  }
  
  /* Styles.css */
.about-me-img {
    width: 85px; /* Adjust this value to your liking */
    height: 85px; /* Adjust this value to your liking */
    margin-left: 20px; /* Adjust this value to your liking */
  }
  
  .about-me-paragraph {
    text-align: left;
    width: 100%;
  }
  
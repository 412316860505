.my-masonry-grid {
    display: flex;
    margin-left: -30px; /* offset of 30px added by .my-masonry-grid_column */
    width: auto;
  }
  
  .my-masonry-grid_column {
    padding-left: 30px; /* space between items */
    background-clip: padding-box;
  }
  
  .my-masonry-grid_column > div {
    margin-bottom: 30px;
  }
  
  #art-gallery h2 {
    font-size: 48px;
    font-weight: bold;
    color: #ffffff;
  }
  
  #art-gallery p {
    font-size: 18px;
    color: #999;
  }
  
  #art-gallery h2, 
#art-gallery p {
  text-align: center;
}
